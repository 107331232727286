import { messaging } from './firebase';
import { getToken } from 'firebase/messaging';
import api from './services/api';

export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Notification permission granted.');

      // Get the registration token
      const token = await getToken(messaging, {
        vapidKey: 'BNG5vt-4PQzzNfOe5vHpgF38Z-OlH5IRSV8y3ObsTBLcfIw3HYukND0YQ-1JDGxxoDycjjrwqqmnPcwAs7L8mV0',
      });

      if (token) {
        console.log('Device token:', token);
        // Send the token to your backend
        await sendTokenToServer(token);
      } else {
        console.warn('No FCM token retrieved. Token is null.');
      }
    } else {
      console.warn('Notification permission not granted.');
    }
  } catch (error) {
    console.error('An error occurred while requesting notification permission:', error);
  }
};

const sendTokenToServer = async (token) => {
  try {
    const response = await api.post('/notifications/register-device-token/', {
      device_token: token,
    });

    if (response.status === 200 || response.status === 201) {
      console.log('Token sent to server successfully.');
    } else {
      console.error('Failed to send token to server.');
    }
  } catch (error) {
    console.error('An error occurred while sending token to server:', error.response?.data || error.message);
  }
};

// Refresh token manually
export const refreshFCMToken = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: 'BNG5vt-4PQzzNfOe5vHpgF38Z-OlH5IRSV8y3ObsTBLcfIw3HYukND0YQ-1JDGxxoDycjjrwqqmnPcwAs7L8mV0',
    });

    if (token) {
      console.log('Refreshed device token:', token);
      // Send the refreshed token to your backend
      await sendTokenToServer(token);
    } else {
      console.warn('No refreshed FCM token retrieved. Token is null.');
    }
  } catch (error) {
    console.error('An error occurred while refreshing token:', error);
  }
};
