import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  InputBase,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  useMediaQuery,
  useTheme,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import ClockInOutButtons from './RightContent/Navbar/ClockInOutButtons';
import NotificationComponent from './RightContent/Navbar/NotificationComponent';
import logo from './logo.png';

const Navbar = ({ selectedOption, toggleSidebar, handleLogout }) => {
  const [anchorEl, setAnchorEl] = useState(null); // State to control avatar dropdown
  const [isSearchOpen, setIsSearchOpen] = useState(false); // State to control search box visibility in mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const open = Boolean(anchorEl); // Determine if the avatar menu is open

  // Handle click on the avatar to open the dropdown
  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the dropdown menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle search toggle in mobile view
  const handleSearchToggle = () => {
    setIsSearchOpen(!isSearchOpen); // Toggle search visibility
  };

  return (
    <AppBar
      position="fixed"
      color="inherit"
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        borderBottom: '1px solid #e0e0e0',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
        {/* Left section with clickable logo to toggle sidebar */}
        <Box display="flex" alignItems="center">
          <Box
            onClick={toggleSidebar}
            sx={{
              width: 80,
              height: 80,
              bgcolor: '#e0e0e0',
              borderRadius: '50%',
              marginRight: 2,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
            }}
          >
            <img src={logo} alt="Logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ fontWeight: 'bold', ml: 2, display: { xs: isSearchOpen ? 'none' : 'block', md: 'block' } }}
          >
            {selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)}
          </Typography>
        </Box>

        {/* Search bar */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: { xs: isSearchOpen ? '100%' : 'auto', md: '400px' },
            ml: 'auto',
            justifyContent: 'flex-end',
          }}
        >
          {isSearchOpen || !isMobile ? (
            <>
              <InputBase
                autoFocus={isMobile && isSearchOpen} // Autofocus only on mobile when open
                placeholder="Search by anything"
                sx={{
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  padding: '0 12px',
                  height: 40,
                  width: '100%',
                  display: { xs: 'block', md: 'block' }, // Always show on desktop
                }}
              />
              {isMobile && (
                <IconButton
                  onClick={handleSearchToggle} // Close the search bar on mobile when close button clicked
                  sx={{
                    height: 40,
                    borderRadius: 0,
                    color: '#333',
                    '&:hover': { backgroundColor: 'transparent' },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </>
          ) : (
            <IconButton
              onClick={handleSearchToggle} // Open search bar on mobile
              sx={{
                color: '#007bff',
                display: { xs: 'block', md: 'none' }, // Only show search button on mobile
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
        </Box>

        {/* Right section with icons */}
        <Box display="flex" alignItems="center">
          {!isMobile && (
            <>
              <NotificationComponent /> {/* Integrated NotificationComponent */}
              <IconButton sx={{ mr: 2 }}>
                <HelpOutlineIcon />
              </IconButton>
              <ClockInOutButtons />
              <IconButton onClick={handleLogout} title="Logout" sx={{ mr: 2 }}>
                <LogoutIcon />
              </IconButton>
            </>
          )}
        </Box>

        {/* Avatar dropdown button */}
        <IconButton onClick={handleAvatarClick} sx={{ p: 0 }}>
          <Avatar sx={{ bgcolor: '#007bff' }}>A</Avatar>
        </IconButton>

        {/* Avatar Menu */}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 1,
            sx: {
              mt: 1.5,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleLogout}>
            <LogoutIcon sx={{ mr: 1 }} /> Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
