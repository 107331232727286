import React, { useState } from 'react';
import { TextField, Checkbox, Chip, Button, Typography, Paper, Box, Snackbar, Alert, Grid, Pagination, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const CreateGroupChat = ({ contacts, onCreateGroupChat }) => {
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [groupName, setGroupName] = useState('');
    const [error, setError] = useState({ message: '', show: false });
    const [searchTerm, setSearchTerm] = useState(''); // For search functionality
    const [currentPage, setCurrentPage] = useState(1); // For pagination
    const [showSearch, setShowSearch] = useState(false); // Toggle search box visibility

    const itemsPerPage = 9; // Number of employees to show per page

    const handleCheckboxChange = (contactId) => {
        setSelectedContacts((prevSelected) =>
            prevSelected.includes(contactId)
                ? prevSelected.filter((id) => id !== contactId)
                : [...prevSelected, contactId]
        );
    };

    const handleCreateGroupChat = () => {
        if (!groupName.trim()) {
            setError({ message: 'Group name cannot be empty.', show: true });
            return;
        }
        if (selectedContacts.length === 0) {
            setError({ message: 'Please select at least one contact.', show: true });
            return;
        }
        onCreateGroupChat(selectedContacts, groupName);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') return;
        setError({ ...error, show: false });
    };

    // Filter contacts based on the search term
    const filteredContacts = contacts.filter((contact) => {
        const contactName = `${contact.first_name || ''} ${contact.last_name || ''}`.trim() || contact.username || 'Unknown User';
        return contactName.toLowerCase().includes(searchTerm.toLowerCase());
    });

    // Separate selected contacts for top listing
    const selectedContactsList = filteredContacts.filter(contact => selectedContacts.includes(contact.id));
    const unselectedContactsList = filteredContacts.filter(contact => !selectedContacts.includes(contact.id));

    // Paginate the unselected contacts
    const paginatedContacts = unselectedContactsList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <Paper elevation={3} style={{ padding: '16px', width: '100%', maxWidth: '600px', margin: '0 auto' }}>
            <Typography variant="h5" gutterBottom>
                Create Group Chat
            </Typography>

            {/* Group Chat Name Input */}
            <TextField
                label="Group Chat Name"
                variant="outlined"
                fullWidth
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                margin="normal"
            />

            {/* Search Bar with Magnifier Icon */}
            <Box display="flex" alignItems="center" marginBottom={2}>
                <IconButton onClick={() => setShowSearch(!showSearch)}>
                    <SearchIcon />
                </IconButton>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: showSearch ? 'block' : 'none',
                        transition: 'all 0.3s ease',
                        overflow: 'hidden',
                    }}
                >
                    <TextField
                        label="Search Employees"
                        variant="outlined"
                        fullWidth
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        margin="normal"
                        placeholder="Search by name..."
                    />
                </Box>
            </Box>

            {/* Selected Contacts Chips */}
            <Box display="flex" flexWrap="wrap" gap={1} marginTop={2}>
                {selectedContactsList.map((contact) => {
                    const contactName = `${contact.first_name || ''} ${contact.last_name || ''}`.trim() || contact.username || 'Unknown User';

                    return (
                        <Chip
                            key={contact.id}
                            label={contactName}
                            onDelete={() => handleCheckboxChange(contact.id)}
                            color="primary"
                        />
                    );
                })}
            </Box>

            {/* Contacts Grid */}
            <Box marginTop={2}>
                <Typography variant="subtitle1" gutterBottom>
                    Select Contacts
                </Typography>
                <Grid container spacing={2}>
                    {paginatedContacts.map((contact) => {
                        const contactName = `${contact.first_name || ''} ${contact.last_name || ''}`.trim() || contact.username || 'Unknown User';

                        return (
                            <Grid item xs={4} key={contact.id}>
                                <Checkbox
                                    checked={selectedContacts.includes(contact.id)}
                                    onChange={() => handleCheckboxChange(contact.id)}
                                    color="primary"
                                />
                                <Typography variant="body2">{contactName}</Typography>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            {/* Pagination Controls */}
            <Box display="flex" justifyContent="center" marginTop={2}>
                <Pagination
                    count={Math.ceil(unselectedContactsList.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>

            {/* Create Group Chat Button */}
            <Button
                onClick={handleCreateGroupChat}
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: '16px' }}
            >
                Create Group Chat
            </Button>

            {/* Error Message Snackbar */}
            <Snackbar
                open={error.show}
                autoHideDuration={6000}
                onClose={handleCloseError}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    {error.message}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default CreateGroupChat;
