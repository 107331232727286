import React, { useState, useEffect } from 'react';
import api from '../../../../../services/api'; 
import { 
  Grid, Card, CardContent, CardActions, Typography, TextField, 
  Button, Divider
} from '@mui/material';

const DepartmentManager = () => {
  const [departments, setDepartments] = useState([]);
  const [name, setName] = useState('');
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await api.get('/departments/departments/');
      setDepartments(response.data);
    } catch (error) {
      console.error('Failed to fetch departments', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editId) {
        // Update existing department
        await api.put(`/departments/departments/${editId}/`, { name });
        setEditId(null);
      } else {
        // Create a new department
        await api.post('/departments/departments/', { name });
      }
      setName('');
      fetchDepartments();
    } catch (error) {
      console.error('Failed to save department', error);
    }
  };

  const handleEdit = (department) => {
    setName(department.name);
    setEditId(department.id);
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/departments/departments/${id}/`);
      fetchDepartments();
    } catch (error) {
      console.error('Failed to delete department', error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>Manage Departments</Typography>

      {/* Department Form */}
      <Card style={{ padding: '20px', marginBottom: '20px' }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Department Name"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                {editId ? 'Update Department' : 'Add Department'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>

      {/* Departments List */}
      <Typography variant="h5" gutterBottom>All Departments</Typography>
      <Divider style={{ marginBottom: '15px' }} />
      <Grid container spacing={3}>
        {departments.map((department) => (
          <Grid item xs={12} sm={6} md={4} key={department.id}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">{department.name}</Typography>
              </CardContent>
              <CardActions>
                <Button 
                  size="small" 
                  color="primary" 
                  onClick={() => handleEdit(department)}
                >
                  Edit
                </Button>
                <Button 
                  size="small" 
                  color="secondary" 
                  onClick={() => handleDelete(department.id)}
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default DepartmentManager;
