import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import UserPermissions from './Base/UserPermissions';
import DepartmentManager from './Base//DepartmentManager';
import PositionManager from './Base/PositionManager';

const Base = () => {
  const [activeTab, setActiveTab] = useState(0); // Track active tab

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', padding: '20px' }}>
      {/* Tab Navigation */}
      <Tabs 
        value={activeTab} 
        onChange={handleTabChange} 
        centered 
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"  // Ensure tabs take the full width
      >
        <Tab label="User Permissions" />
        <Tab label="Department Management" />
        <Tab label="Position Management" />
      </Tabs>

      {/* Tab Content */}
      <Box sx={{ marginTop: '20px' }}>
        {activeTab === 0 && (
          <Box>
            <Typography variant="h5" gutterBottom>User Permissions</Typography>
            <UserPermissions />
          </Box>
        )}
        {activeTab === 1 && (
          <Box>
            <Typography variant="h5" gutterBottom>Department Management</Typography>
            <DepartmentManager />
          </Box>
        )}
        {activeTab === 2 && (
          <Box>
            <Typography variant="h5" gutterBottom>Position Management</Typography>
            <PositionManager />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Base;
