import React from 'react';

const Sidebar = ({ 
    recentChats = [], 
    contacts = [], 
    activeChat, 
    onSelectChat, 
    onStartChat, 
    currentUser, 
    unreadCounts, 
    onShowCreateGroupChat 
}) => {
    return (
        <div className="w-72 bg-gray-100 p-4 overflow-y-auto border-r border-gray-300 flex flex-col relative">
            <h2 className="text-2xl mb-4 text-blue-700">Chat</h2>
            
            {/* Button to Create Group Chat */}
            <button
                onClick={onShowCreateGroupChat}
                className="mb-4 bg-green-500 text-white px-4 py-2 rounded"
            >
                Create Group Chat
            </button>
            
            {/* Recent Chats Section */}
            <div>
                <h3 className="text-lg mb-2 text-gray-600">Recent Chats</h3>
                {recentChats.map((chat) => {
                    const unreadCount = unreadCounts?.[chat.id] || 0;

                    // Determine display name for group or private chats
                    let displayName;
                    if (chat.is_private === false) {
                        // Group chats display the group name
                        displayName = chat.name || "Unnamed Group Chat";
                    } else {
                        // Private chats display names of other participants
                        const otherParticipants = chat.participants
                            ?.filter((participant) => participant.id !== currentUser?.id);
                        
                        displayName = otherParticipants && otherParticipants.length > 0 
                            ? otherParticipants.map((p) => 
                                `${p.first_name || ''} ${p.last_name || ''}`.trim() || p.username || p.email || "Unknown User"
                              ).join(", ")
                            : "Unnamed Chat";
                    }

                    return (
                        <div
                            key={chat.id}
                            className={`p-3 mb-2 rounded cursor-pointer ${
                                activeChat?.id === chat.id
                                    ? 'bg-blue-300 text-black border border-blue-500'
                                    : unreadCount > 0
                                        ? 'bg-yellow-200 text-gray-800 border border-yellow-400'
                                        : 'bg-white text-gray-800 border border-gray-200'
                            }`}
                            onClick={() => onSelectChat(chat)}
                        >
                            <div className="flex justify-between items-center">
                                <span>
                                    {displayName}
                                    {chat.is_private ? (
                                        <span className="text-xs text-gray-500 ml-2">(Private)</span>
                                    ) : (
                                        <span className="text-xs text-gray-500 ml-2">(Group)</span>
                                    )}
                                </span>
                                {unreadCount > 0 && (
                                    <span className="text-sm bg-red-500 text-white rounded-full h-5 w-5 flex items-center justify-center">
                                        {unreadCount}
                                    </span>
                                )}
                            </div>
                            <div className="text-sm text-gray-500">{chat.last_message || "No messages yet"}</div>
                        </div>
                    );
                })}
            </div>

            {/* Contacts Section */}
            <div className="mt-6">
                <h3 className="text-lg mb-2 text-gray-600">Contacts</h3>
                {contacts.map((contact) => {
                    if (contact.id === currentUser?.id) return null; // Skip current user
                    
                    const contactName = `${contact.first_name} ${contact.last_name}`.trim() || contact.username || "Unknown User";
                    
                    return (
                        <div
                            key={contact.id}
                            className="p-3 mb-2 rounded cursor-pointer bg-white text-gray-800 border border-gray-200 hover:bg-gray-200"
                            onClick={() => onStartChat(contact)}
                        >
                            <span>{contactName}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Sidebar;
