import React, { useState, useEffect } from 'react';
import api from '../../../../../services/api';
import { 
  Grid, Card, CardContent, CardActions, Typography, TextField, 
  Select, MenuItem, InputLabel, FormControl, Checkbox, 
  FormControlLabel, Button, Divider, Pagination 
} from '@mui/material';

const PositionManager = () => {
  const [positions, setPositions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isExecutive, setIsExecutive] = useState(false);
  const [level, setLevel] = useState('');
  const [department, setDepartment] = useState('');
  const [reportsTo, setReportsTo] = useState(null);
  const [editId, setEditId] = useState(null);

  const [page, setPage] = useState(1); // Track current page
  const positionsPerPage = 9; // 3x3 layout

  useEffect(() => {
    fetchPositions();
    fetchDepartments();
  }, []);

  const fetchPositions = async () => {
    try {
      const response = await api.get('/departments/positions/');
      setPositions(response.data);
    } catch (error) {
      console.error('Failed to fetch positions', error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await api.get('/departments/departments/');
      setDepartments(response.data);
    } catch (error) {
      console.error('Failed to fetch departments', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const positionData = {
      title,
      description,
      is_executive: isExecutive,
      level,
      department,
      reports_to: reportsTo,
    };

    try {
      if (editId) {
        await api.put(`/departments/positions/${editId}/`, positionData);
        setEditId(null);
      } else {
        await api.post('/departments/positions/', positionData);
      }
      resetForm();
      fetchPositions();
    } catch (error) {
      console.error('Failed to save position', error);
    }
  };

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setIsExecutive(false);
    setLevel('');
    setDepartment('');
    setReportsTo(null);
  };

  const handleEdit = (position) => {
    setTitle(position.title);
    setDescription(position.description);
    setIsExecutive(position.is_executive);
    setLevel(position.level);
    setDepartment(position.department.id);
    setReportsTo(position.reports_to ? position.reports_to.id : null);
    setEditId(position.id);
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/departments/positions/${id}/`);
      fetchPositions();
    } catch (error) {
      console.error('Failed to delete position', error);
    }
  };

  // Group positions by department
  const groupedPositions = positions.reduce((acc, position) => {
    const departmentName = position.department ? position.department.name : 'No Department';
    if (!acc[departmentName]) acc[departmentName] = [];
    acc[departmentName].push(position);
    return acc;
  }, {});

  // Pagination - Calculate current page positions
  const paginatedPositions = Object.keys(groupedPositions).map((departmentName) => {
    const positionsInDept = groupedPositions[departmentName];
    const indexOfLastPosition = page * positionsPerPage;
    const indexOfFirstPosition = indexOfLastPosition - positionsPerPage;
    return {
      departmentName,
      positions: positionsInDept.slice(indexOfFirstPosition, indexOfLastPosition),
    };
  });

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>Manage Positions</Typography>

      {/* Position Form */}
      <Card style={{ padding: '20px', marginBottom: '20px' }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Position Title"
                variant="outlined"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Level"
                variant="outlined"
                fullWidth
                value={level}
                onChange={(e) => setLevel(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Department</InputLabel>
                <Select
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                  label="Department"
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {departments.map((dept) => (
                    <MenuItem key={dept.id} value={dept.id}>
                      {dept.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Reports To</InputLabel>
                <Select
                  value={reportsTo}
                  onChange={(e) => setReportsTo(e.target.value)}
                  label="Reports To"
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {positions.map((pos) => (
                    <MenuItem key={pos.id} value={pos.id}>
                      {pos.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isExecutive}
                    onChange={() => setIsExecutive(!isExecutive)}
                  />
                }
                label="Executive Position"
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                {editId ? 'Update Position' : 'Add Position'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>

      {/* Department-wise Positions List */}
      {paginatedPositions.map(({ departmentName, positions }) => (
        <div key={departmentName} style={{ marginBottom: '30px' }}>
          <Typography variant="h5" gutterBottom>
            {departmentName} Department
          </Typography>
          <Divider style={{ marginBottom: '15px' }} />
          <Grid container spacing={3}>
            {positions.map((position) => (
              <Grid item xs={12} sm={6} md={4} key={position.id}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6">{position.title}</Typography>
                    <Typography variant="body2">{position.description}</Typography>
                    <Typography variant="body2">Level: {position.level || 'N/A'}</Typography>
                    <Typography variant="body2">
                      Reports To: {position.reports_to ? position.reports_to.title : 'N/A'}
                    </Typography>
                    <Typography variant="body2">
                      {position.is_executive ? 'Executive Position' : 'Non-Executive Position'}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button 
                      size="small" 
                      color="primary" 
                      onClick={() => handleEdit(position)}
                    >
                      Edit
                    </Button>
                    <Button 
                      size="small" 
                      color="secondary" 
                      onClick={() => handleDelete(position.id)}
                    >
                      Delete
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      ))}

      {/* Pagination */}
      <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
        <Pagination
          count={Math.ceil(positions.length / positionsPerPage)}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </Grid>
    </div>
  );
};

export default PositionManager;
