import React from 'react';
import RoleBasedDashboard from './Dashboard/RoleBasedDashboard';

const DashboardPage = () => {
  return (
    <div>
      <RoleBasedDashboard />
    </div>
  );
};

export default DashboardPage;