// NotificationComponent.js
import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Badge,
  Typography,
  Divider,
  Box,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import api from '../../../services/api';
import Cookies from 'js-cookie';

const NotificationComponent = () => {
  const [notifications, setNotifications] = useState([]); // Store notifications
  const [unreadCount, setUnreadCount] = useState(0); // Unread notifications count
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null); // Notification menu state
  const [socket, setSocket] = useState(null); // WebSocket connection
  const [newNotification, setNewNotification] = useState(null); // Snackbar for new notification

  const notificationOpen = Boolean(notificationAnchorEl);

  // Fetch notifications
  const fetchNotifications = async () => {
    try {
      const response = await api.get('/notifications/notifications/');
      setNotifications(response.data);
      const unread = response.data.filter((n) => !n.read).length;
      setUnreadCount(unread);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  // Mark notification as read
  const handleMarkAsRead = async (id) => {
    try {
      await api.post(`/notifications/notifications/${id}/mark-as-read/`);
      setNotifications((prev) =>
        prev.map((n) => (n.id === id ? { ...n, read: true } : n))
      );
      setUnreadCount((prev) => prev - 1);
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  // Clear all notifications
  const handleClearAllNotifications = async () => {
    try {
      await api.delete('/notifications/notifications/clear-all/');
      setNotifications((prev) => prev.map((n) => ({ ...n, read: true })));
      setUnreadCount(0);
    } catch (error) {
      console.error('Error clearing notifications:', error);
    }
  };

  // WebSocket connection for live notifications
  useEffect(() => {
    const token = Cookies.get('access');
    const wsUrl = `ws://localhost:8000/ws/notifications/?token=${token}`;
    const webSocket = new WebSocket(wsUrl);

    webSocket.onopen = () => {
      console.log('WebSocket connection established');
    };

    webSocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    webSocket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    webSocket.onmessage = (message) => {
      const notification = JSON.parse(message.data);
      setNotifications((prev) => [notification, ...prev]);
      if (!notification.read) {
        setUnreadCount((prev) => prev + 1);
        setNewNotification(notification.message); // Trigger snackbar
      }
    };

    setSocket(webSocket);
    return () => {
      webSocket.close();
    };
  }, []);

  // Open notification menu
  const handleNotificationsClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
    fetchNotifications();
  };

  // Close notification menu
  const handleNotificationsClose = () => {
    setNotificationAnchorEl(null);
  };

  return (
    <>
      {/* Notification Icon */}
      <IconButton onClick={handleNotificationsClick}>
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      {/* Notification Menu */}
      <Menu
        anchorEl={notificationAnchorEl}
        open={notificationOpen}
        onClose={handleNotificationsClose}
        PaperProps={{
          elevation: 1,
          sx: {
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2, py: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Notifications
          </Typography>
          <Button
            variant="text"
            onClick={handleClearAllNotifications}
            sx={{ color: 'red', fontSize: '0.8rem', textTransform: 'none' }}
          >
            Clear All
          </Button>
        </Box>
        <Divider />
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <MenuItem
              key={notification.id}
              onClick={() => handleMarkAsRead(notification.id)}
              sx={{ backgroundColor: notification.read ? 'inherit' : '#f5f5f5' }}
            >
              {notification.message}
            </MenuItem>
          ))
        ) : (
          <MenuItem>
            <Typography variant="body2">No new notifications</Typography>
          </MenuItem>
        )}
      </Menu>

      {/* Snackbar for new notification */}
      <Snackbar
        open={Boolean(newNotification)}
        autoHideDuration={6000}
        onClose={() => setNewNotification(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setNewNotification(null)} severity="info" sx={{ width: '100%' }}>
          {newNotification}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NotificationComponent;
